<template>
  <tr>
    <td class="justify-center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn :disabled="props.item.models && !props.item.models.length" color="green" outline small @click="$router.push({ name: 'network-list', params: { page: 'producer-model' }, query: { producer_id: props.item.id } })">
              <v-icon>list</v-icon>
              <span>Listar Modelos</span>
            </v-btn>
          </div>
        </template>
        <span>Acessar equipamentos deste fabricante</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn color="primary" outline small :to="{ name: 'network-list', params: { page: 'host' }, query: { producer_id: props.item.id } }">
              <v-icon>router</v-icon>
              <span>Listar Equipamentos</span>
            </v-btn>
          </div>
        </template>
        <span>Acessar equipamentos deste fabricante</span>
      </v-tooltip>
    </td>
    <td class="justify-left">
      <strong>
        {{ props.item.id }}
      </strong>
    </td>
    <td class="justify-left">
      <strong style="font-size: 20px;">
        {{ props.item.name }}
      </strong>
      <br>
      <span>{{ props.item.oficial_name }}</span>
    </td>
    <td class="justify-left">
      <strong v-if="props.item.models" style="font-size: 30px;">
        {{ props.item.models.length }}
      </strong>
      <strong v-else style="font-size: 30px;">- - -</strong>
    </td>
  </tr>
</template>
<script>
  export default {
    name: 'Producers',
    props: {
      config: Object,
      props: Object
    }
  }
</script>
<style>
td {
  padding: 0 5px !important;
}
th {
  padding: 0 5px !important;
}
</style>